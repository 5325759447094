export default {
  'system.title': 'System Settings',
  'system.event': 'Event Func',
  'system.event.des': 'Is event function supported',
  'system.plan': 'Special Plan',
  'system.plan.des': 'A plan that can be executed periodically, such as daily, weekly, monthly',
  'system.patroltime': 'Patrol Time',
  'system.patroltime.des':
    'Whether the total planned patrol time of each circle is displayed in the patrol Report',
  'system.remaintime': 'Remain Time',
  'system.remaintime.des':
    'Display the inspection time of the same inspection point in the planned time period in the inspection report',
  'system.Sequence': 'Sequence Patrol',
  'system.Sequence.des':
    'Whether to specify that each checkpoint in the plan must be inspected in the order set in the plan',
  'system.dst': 'Daylight Saving Time',
  'system.dst.des': 'Whether the enterprise enables the DST mode',
  'system.dst.preview': 'DST period: {beginTime} ~ {endTime}, span: {spanHour} hours',
  'system.dst.beginMonth': 'Starting month',
  'system.dst.endMonth': 'End month',
  'system.dst.spanHour': 'Span (hours)',
  'system.dst.weekVal': 'week',
  'system.dst.weekIndex': 'The No.{indexVal}',
  'system.dst.dataNullErrorMsg': 'The DST is not configured. Change it',
  'system.dst.beginMonthGeEndError': 'The start month must be less than the end month',
  'system.dst.weekIndex.tooltip':
    'If there is no specified number of weeks in the month, the program will be calculated according to the specified week of the last week.',
  'system.timeformate': 'Date Format',
  'system.timeformate.des': 'Date format displayed by the system',
  'system.database.period': 'Database backup cycle',
  'system.database.period.des': 'How often is the database backed up, unit: day',
  'system.database.time': 'Database backup time',
  'system.database.time.des': 'Please try to select the low peak time for data uploading',
  'system.database.path': 'Database backup path',
  'system.database.path.des':
    'Please enter the database backup path, for example: d:\\backup. If the path is empty, the database will not be backed up',
  'system.save': 'Save',
  'system.enable': 'Enable',
  'system.disable': 'Disable',
  'system.notification.prompt': 'Prompt',
  'system.notification.success': 'Save Successfully',
  'system.deptBaseDataCalMode.title': 'Region basic data calculation mode',
  'system.deptBaseDataCalMode.desc':
    'This section describes how to calculate the number of basic types on each basic data management page and area tree nodes',
  'system.deptBaseDataCalMode.options.currentDept': 'Only the current region is calculated',
  'system.deptBaseDataCalMode.options.childrenDept':
    'Calculate the current region and subordinate ownership',
  'system.lowAutoSendInterval.title': 'Low battery alarm interval (unit: minutes)',
  'system.lowAutoSendInterval.desc':
    'Set the time interval for pushing data and reminding users when the device has a low battery alarm',
  'system.realTimeMonitorModule.title': 'Real-time monitoring mode',
  'system.realTimeMonitorModule.desc':
    'Setting up real-time monitoring of the use of maps or diagrams also affects whether the electronic fence is effective',
  'system.realTimeMonitorModule.options.map': 'Online map',
  'system.realTimeMonitorModule.options.imgFile': 'Schematic drawing',
  'system.compantTimezoneUpt.syncMsg':
    'If the enterprise default time zone has changed, determine whether to synchronize the regional time zone that has not changed',
  'system.timezone.des':
    'The current enterprise default time zone is mainly used for regional time zone configuration and device time calibration',
  'system.deptTimezone.title': 'Regional time zone Settings',
  'system.deptTimezone.des':
    'Whether to adjust time zones by region. After this function is enabled, the device can manage the time zone by itself. After this function is disabled, the time zone is restored to the enterprise time zone.',
  'system.back.title': 'Database backup',
  'system.back.desc': 'Much system data or restore the system data to a certain point in time',
  'system.back.lastBackTime': 'Last backup time:',
  'system.back.managementBack': 'Administrative backup',
  'system.deptEmail': 'Regional mail service',
  'system.deptEmail.des':
    'After activation, inspection related data will be pushed to the email set for the region',
  'system.deptEmail.enableOmit': 'Push the omission data',
  'system.deptEmail.enableAlarm': 'Push unplanned alarms',
  'system.deptEmail.enableReport': 'Push the daily inspection report',
  'system.deptEmail.emailServerHost': 'Email server address',
  'system.deptEmail.emailServerAccount': 'Email service account',
  'system.deptEmail.emailServerPwd': 'Email service password',
  'system.enclosureModule.title': 'Electronic fence function',
  'system.enclosureModule.des':
    'Whether to enable the electronic fence function. If the real-time monitoring mode is selected according to the schematic diagram, even if it is enabled, the electronic fence function will be blocked.',
};
