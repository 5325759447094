export default {
  'system.title': 'Ρυθμίσεις συστήματος',
  'system.event': 'Λειτουργία Συμβάντων',
  'system.event.des': 'Αν υποστηρίζεται λειτουργία συμβάντων',
  'system.plan': 'Ειδικό Πλάνο',
  'system.plan.des':
    'Το πλάνο μπορεί να εκτελείται περιοδικά, δηλαδή ημερήσια, εβδομαδιαία, μηνιαία',
  'system.patroltime': 'Χρόνος Περιπολίας',
  'system.patroltime.des':
    'Αν ο συνολικό χρόνος περιπολίας κάθε κύκλου θα θα εμφανίζεται στην αναφορά περιπολιών',
  'system.remaintime': 'Χρόνος που απομένει',
  'system.remaintime.des':
    'Εμφανίστε τον χρόνο επιθεώρησης του ίδιου σημείου επιθεώρησης στην προγραμματισμένη χρονική περίοδο στην τελική αναφορά',
  'system.Sequence': 'Ακολουθία περιπολίας',
  'system.Sequence.des':
    'Εάν θα καθοριστεί ότι κάθε σημείο ελέγχου πρέπει να επιθεωρηθεί με τη σειρά που ορίζεται στο πλάνο',
  'system.dst': 'Θερινή ώρα',
  'system.dst.des': 'Εάν η τρέχουσα επιχείρηση έχει ενεργοποιημένη τη θερινή ώρα',
  'system.dst.preview': 'Θερινή χρονική περίοδος: {beginTime}~{endTime}, διάρκεια: {spanHour} ώρες',
  'system.dst.beginMonth': 'Μήνας έναρξης',
  'system.dst.endMonth': 'Τέλος μήνα',
  'system.dst.spanHour': 'Διάρκεια (ώρες)',
  'system.dst.weekVal': 'εβδομάδα',
  'system.dst.weekIndex': '{indexVal}',
  'system.dst.dataNullErrorMsg': 'Η θερινή ώρα δεν έχει ρυθμιστεί, τροποποιήστε την',
  'system.dst.beginMonthGeEndError':
    'Ο μήνας έναρξης πρέπει να είναι μικρότερος από τον τελικό μήνα',
  'system.dst.weekIndex.tooltip':
    'Εάν δεν υπάρχει καθορισμένος αριθμός εβδομάδων σε ένα μήνα, το πρόγραμμα θα υπολογιστεί με βάση την καθορισμένη εβδομάδα της τελευταίας εβδομάδας.',
  'system.timeformate': 'Μορφή Ημερομηνίας',
  'system.timeformate.des': 'Η μορφή ημερομηνίας που εμγφανίζεται στο σύστημα',
  'system.database.period': 'Κύκλος δημιουργίας backup βάσης δεδομένων',
  'system.database.period.des': 'Πόσο συχνά γίνεται back η βάση δεδομένων, μονάδα: ημέρα',
  'system.database.time': 'Χρόνος backup βάσης δεδομένων',
  'system.database.time.des':
    'Προσπαθήστε να επιλέξετε ώρα χαμηλής αιχμής για τη μεταφόρτωση δεδομένων',
  'system.database.path': 'Διαδρομή backup Βάσης δεδομένων',
  'system.database.path.des':
    'Παρακαλώ εισάγετε τη διαδρομή backup βάσης δεδομένων, για παράδειγμα: d:\\backup. Αν η διαδρομή είναι κενή η βάση δεδομένων δεν θα γίνει backup',
  'system.save': 'Αποθήκευση',
  'system.enable': 'Ενεργοποίηση',
  'system.disable': 'Απενεργοποίηση',
  'system.notification.prompt': 'Προτροπή',
  'system.notification.success': 'Επιτυχής αποθήκευση',
  'system.deptBaseDataCalMode.title': 'Η μέθοδος υπολογισμού των βασικών δεδομένων της περιοχής',
  'system.deptBaseDataCalMode.desc':
    'Η μέθοδος υπολογισμού του αριθμού των βασικών τύπων σε κάθε βασική διεπαφή διαχείρισης δεδομένων και ο περιφερειακός κόμβος δέντρου κάτω από τις ρυθμίσεις επιθεώρησης',
  'system.deptBaseDataCalMode.options.currentDept': 'Υπολογίζεται μόνο η τρέχουσα περιοχή',
  'system.deptBaseDataCalMode.options.childrenDept':
    'Υπολογίζει την τρέχουσα περιοχή και όλους τους υφισταμένους',
  'system.lowAutoSendInterval.title': 'Διάστημα συναγερμού χαμηλής μπαταρίας (μονάδα: λεπτά)',
  'system.lowAutoSendInterval.desc':
    'Ορίστε το χρονικό διάστημα κατά το οποίο η συσκευή είναι χαμηλή μπαταρία συναγερμού, πιέστε τα δεδομένα και υπενθυμίστε στο χρήστη',
  'system.realTimeMonitorModule.title': 'Λειτουργία παρακολούθησης σε πραγματικό χρόνο',
  'system.realTimeMonitorModule.desc':
    'Η ρύθμιση παρακολούθησης σε πραγματικό χρόνο χρησιμοποιώντας χάρτες ή σχηματικά διαγράμματα επηρεάζει επίσης εάν ο φράκτης είναι αποτελεσματικός',
  'system.realTimeMonitorModule.options.map': 'Online χάρτες',
  'system.realTimeMonitorModule.options.imgFile': 'Διάγραμμα',
  'system.compantTimezoneUpt.syncMsg':
    'Εάν αλλάξει η προεπιλεγμένη ζώνη ώρας της εταιρείας, συγχρονίζεται η τοπική ζώνη ώρας που δεν έχει αλλάξει',
  'system.timezone.des':
    'Η προεπιλεγμένη ζώνη ώρας της τρέχουσας επιχείρησης εφαρμόζεται κυρίως στη ρύθμιση παραμέτρων ζώνης ώρας της περιοχής και στη βαθμονόμηση ώρας της συσκευής',
  'system.deptTimezone.title': 'Τοπικές ρυθμίσεις ζώνης ώρας',
  'system.deptTimezone.des':
    'Εάν ενεργοποιήσετε αυτήν τη δυνατότητα, θα έχετε τη δυνατότητα να διαχειριστείτε τη ζώνη ώρας μόνοι σας και να επαναφέρετε τη ζώνη ώρας στην εταιρική ζώνη ώρας αφού την απενεργοποιήσετε.',
  'system.back.title': 'Αντίγραφα ασφαλείας βάσης δεδομένων',
  'system.back.desc': 'Αντιγραφή ή επαναφορά δεδομένων συστήματος σε ένα χρονικό σημείο',
  'system.back.lastBackTime': 'Τελευταίος χρόνος δημιουργίας αντιγράφων ασφαλείας:',
  'system.back.managementBack': 'Διαχείριση αντιγράφων ασφαλείας',
  'system.deptEmail': 'Περιφερειακή ταχυδρομική υπηρεσία',
  'system.deptEmail.des':
    'Μετά την ενεργοποίηση, τα σχετικά με την επιθεώρηση δεδομένα θα προωθηθούν στο ηλεκτρονικό ταχυδρομείο που έχει οριστεί για την περιοχή',
  'system.deptEmail.enableOmit': 'Συναγερμός χαμένης ανίχνευσης σχεδίου ώθησης',
  'system.deptEmail.enableAlarm': 'Πιέστε μη προγραμματισμένους συναγερμούς',
  'system.deptEmail.enableReport': 'Σπρώξτε την ημερήσια έκθεση επιθεώρησης',
  'system.deptEmail.emailServerHost': 'Διεύθυνση διακομιστή ηλεκτρονικού ταχυδρομείου',
  'system.deptEmail.emailServerAccount': 'Λογαριασμός υπηρεσίας ηλεκτρονικού ταχυδρομείου',
  'system.deptEmail.emailServerPwd': 'Κωδικός πρόσβασης υπηρεσίας ηλεκτρονικού ταχυδρομείου',
  'system.enclosureModule.title': 'Λειτουργία ηλεκτρονικού φράκτη',
  'system.enclosureModule.des':
    'Εάν θα ενεργοποιηθεί η ηλεκτρονική λειτουργία φρακτών. Εάν ο τρόπος παρακολούθησης σε πραγματικό χρόνο επιλέγεται σύμφωνα με το σχηματικό διάγραμμα, ακόμα και αν είναι ενεργοποιημένος, η ηλεκτρονική λειτουργία φρακτών θα μπλοκαριστεί.',
};
