export default {
  'query.condition.dept': 'Название объекта',
  'query.condition.guard': 'Сотрудник',
  'query.condition.site': 'Название метки',
  'query.condition.event': 'Название события',
  'query.condition.plan': 'Название графика',
  'query.condition.planguard': 'Сотрудник',
  'query.condition.device': 'ID устройства',
  'query.condition.begin': 'Дата начала',
  'query.condition.end': 'Дата окончания',
  'query.condition.time': 'Время обхода',
  'query.condition.alarmtype': 'Тип тревоги',
  'query.input.placeholder.site': 'Выберите место',
  'query.input.placeholder.guard': 'Выберите человека',
  'query.input.placeholder.device': 'Выберите устройство',
  'query.input.placeholder.plan': 'Выберите план',
  'query.input.placeholder.alarm': 'Пожалуйста, выберите тип будильника',
  'query.history.title': 'Журнал данных',
  'query.history.btn.reanalysis': 'Повторный анализ',
  'query.app.remark': 'Комментарии',
  'query.app.multimedia': 'Файлы',
  'query.app.view': 'Просмотр',
  'query.app.notes': 'Заметки',
  'query.app.view.pict': 'Просмотр Фото',
  'query.app.view.video': 'Просмотр видео',
  'query.app.view.audio': 'Прослушивание аудио',
  'query.app.options': 'Параметр',
  'query.app.state': 'состояние',
  'query.app.notentered': 'Не введено',
  'query.app.notselected': 'Не выбрано',
  'query.app.selected': 'выбрано',
  'query.app.site.errspan': 'Радиус погрешности выходит за пределы диапазона',
  'query.app.site.time': 'время патрулирования может быть изменено',
  'query.app.site.both':
    'Радиус погрешности выходит за пределы диапазона, одновременно время патрулирования может быть изменено',
  'query.alarm.title': 'Тревоги',
  'query.alarm.btn.del': 'Удалить данные',
  'query.alarm.type.all': 'Все',
  'query.alarm.type.manual': 'Кнопка SOS',
  'query.alarm.type.lowvoltage': 'Низкий заряд',
  'query.alarm.type.incline': 'Наклон',
  'query.alarm.type.static': 'Статический',
  'query.alarm.type.impact': 'Удар',
  'query.alarm.type.open': 'Тревога о неисправности',
  'query.alarm.info': 'Информация',
  'query.alarm.time': 'Время тревоги',
  'query.alarm.position': 'Местоположение тревоги',
  'query.omit.title': 'С нарушениями',
  'query.omit.rangetime': 'Время начала и окончания',

  'query.chart.title': 'Данные диаграммы',
  'query.chart.table.plantimes': 'Количество планов',
  'query.chart.table.arrivedtimes': 'Прибытие',
  'query.chart.table.omittimes': 'Пробелы',
  'query.chart.table.arrivedrate': 'Прибытий, %',
  'query.chart.table.omitrate': 'Не выполнено, %',
  'query.chart.table.summary': 'Сводка данных',
  'query.chart.tab.data': 'Статистика',
  'query.chart.tab.chart': 'Отображение диаграммы',
  'query.chart.tab.chart.column': 'Колонка',
  'query.chart.tab.chart.bar': 'гистограмма',
  'query.chart.tab.chart.line': 'Линия',
  'query.chart.tab.omit': 'С нарушениями',
  'query.chart.condition.type': 'тип статистики',
  'query.chart.condition.dept': 'По объекту',
  'query.chart.condition.guard': 'По сотруднику',
  'query.chart.condition.site': 'По меткам',
  'query.chart.condition.plan': 'Статистика по графику',

  'query.raw.title': 'Информация об исходных данных',
  'query.raw.table.card': 'номер карты',
  'query.raw.table.type': 'тип карты',
  'query.raw.table.name': 'имя номера карты',
  'query.raw.table.unknow': 'Неизвестная карта',
  'query.raw.table.event': 'Карточка события',
  'query.raw.table.guard': 'карточка человека',
  'query.raw.table.site': 'карточка сайта',
  'query.raw.table.time': 'Время получения',
  'query.raw.btn.unknow': 'Неизвестная конверсия карты',
  'query.raw.btn.site': 'Преобразовать в карточку сайта',
  'query.raw.btn.guard': 'Перейти к карточке персонала',
  'query.raw.btn.event': 'Перейти к карточке события',

  'query.log.title': 'Данные системного журнала',
  'query.log.table.operate': 'Интерфейс',
  'query.log.table.content': 'Действие',
  'query.log.table.time': 'Время операции',
  'query.log.table.user': 'Пользователь',
  'query.log.form.dept': 'Настройка объектов',
  'query.log.form.guard': 'Настройка сотрудников',
  'query.log.form.site': 'Настройка меток',
  'query.log.form.plan': 'Настройка графиков',
  'query.log.form.read': 'Прочитать данные',

  'query.calendar.january': 'Январь',
  'query.calendar.february': 'февраль',
  'query.calendar.march': 'Март',
  'query.calendar.april': 'Апрель',
  'query.calendar.may': 'май',
  'query.calendar.june': 'июнь',
  'query.calendar.july': 'июль',
  'query.calendar.august': 'Август',
  'query.calendar.september': 'сентябрь',
  'query.calendar.october': 'октябрь',
  'query.calendar.november': 'ноябрь',
  'query.calendar.december': 'декабрь',
  'query.calendar.year': 'год',
  'query.calendar.year1': 'год',
  'query.calendar.month': 'месяц',

  'query.report.condition.type': 'Тип данных',
  'query.report.condition.all': 'Все данные',
  'query.report.condition.normal': 'Подходящие данные',
  'query.report.condition.arrived': 'Данные о прибытии',
  'query.report.condition.todo': 'Данные для посещения',
  'query.report.table.alltimes': 'Все время',
  'query.report.table.patroltimes': 'Время патрулирования',
  'query.report.table.topatrol': 'Количество обходов',
  'query.report.btn.export.summary': 'Экспорт сводной таблицы',
  'query.report.btn.export.ordinary': 'График экспорта',
  'query.report.table.sequence.right': 'Правильный порядок',
  'query.report.table.sequence.err': 'Последовательная ошибка',
  'query.report.btn.export.summaryname': 'Сводные данные отчета',
  'query.report.btn.export.ordinaryname': 'Подробные данные отчета',
  'query.report.table.step': 'Количество шагов',
  'query.report.btn.del': 'Удалить отчет о проверке',

  'query.history.analysis': 'Вы уверены, что хотите повторно проанализировать выбранные записи? ',
  'query.content.fold': 'развал',
  'query.content.expand': 'Развернуть все',
  'query.report.table.status': 'Тип инспекции',
};
