export default {
  'dept.title': 'Company Name',
  'dept.btn.add': 'Add Company',
  'dept.btn.addSubsidiary': 'Add Subsidiary',
  'dept.btn.edit': 'Edit Company',
  'dept.btn.del': 'Delete Company',
  'dept.btn.home': 'Return Home',
  'dept.input.dept.placeholder': 'Please input company name',
  'dept.input.email.btn': 'append',
};
