export default {
  'map.alarmcontentinfo.alarmdetail': '警報詳情:',
  'map.alarmcontentinfo.unkonwdetail': '未知詳情',
  'map.alarmcontentinfo.iknow': '處理',

  'map.devicecontentinfo.unkonwperson': '未知人員',
  'map.devicecontentinfo.track.timerange': '軌跡數據回放的時間範圍',
  'map.devicecontentinfo.track.speed': '回放速度(km/h)',
  'map.devicecontentinfo.track.begin': '開始回放',
  'map.devicecontentinfo.track.splitTime': '分段間隔時間（分鐘）',
  'map.devicecontentinfo.track.splitDistance': '分段間隔距離（米）',

  'map.floatingtoolbar.track.replay': '重新回放',
  'map.floatingtoolbar.track.pause': '暫停',
  'map.floatingtoolbar.track.resume': '繼續',
  'map.floatingtoolbar.track.clear': '退出軌跡',
  'map.floatingtoolbar.track.resetlimit': '重置條件',

  'map.device.deviceno': '設備編號：',
  'map.search.nocondition.msg': '請輸入檢索條件！',
  'map.track.notime.msg': '請選擇軌跡回放時間範圍！',
  'map.track.empty': '無有效軌跡數據！',
  'map.site.sitename': '地點名稱',
  'map.site.address': '地址',
  'map.module.title': '即時監控',
  'map.sitecontentinfon.unknowdept': '未知區域',
  'map.sitecontentinfon.unknowperson': '未知人員',
  'map.sitecontentinfon.actionhistory': '查看歷史',
  'map.topcomponent.select.plan': '請選擇計劃',
  'map.topcomponent.device.onlinetime': '設備在線時長(單位：分鐘)',
  'map.topcomponent.screen.full': '切換全屏',

  'map.realdata.table.deptname': '區域名稱',
  'map.realdata.table.sitename': '地點名稱',
  'map.realdata.table.guardname': '人員名稱',
  'map.realdata.table.guardcode': '設備號碼',
  'map.realdata.table.happentime': '巡檢時間',

  'map.realdata.changepage.old': '切換到舊版地圖頁',
  'map.realdata.changepage.new': '切換到新版地圖頁',
  'map.realdata': '即時數據',

  'map.google.normal': '普通',
  'map.google.satellite': '衛星',

  'map.videomodal.title': '視頻',
  'map.audiomodal.title': '音頻',
  'map.button.close': '關閉',

  'map.playback': '軌跡回放',
  'map.playback.over': '軌跡回放結束',
  'map.playback.mile': '米',
  'map.playback.timespan': '軌跡回放時間段不能超過2天，請重新選擇日期範圍',

  'map.load.timeout': '地圖加載異常，頁面需要重新加載',

  'map.cluster.more': '更多座標請放大地圖查看',
  'map.site.add.confirm': '確定要將當前選擇的計劃下的點全部添加到地圖中嗎？',
  'map.site.del.confirm': '確定要將當前選擇的計劃下的點全部從地圖中刪除嗎？',
  'map.site.del.info': '請選擇一個計劃進行操作',
  'map.planImg.warn.planNotImgMsg': '當前計劃沒有示意圖，請上傳',
  'map.planImg.upload.label': '導入圖片',
  'map.planImg.warn.siteNotHaveFlag': '當前計劃巡檢點暫未標記',
  'map.planImg.warn.outOfRange': '標記位置超出示意圖範圍，請重新標記',
  'map.planImg.mode.update': '編輯模式',
  'map.planImg.btn.addFlag': '新增標記',
  'map.planImg.btn.clearFlagState': '取消標點狀態',
  'map.planImg.btn.uptImg': '修改圖片',
  'map.planImg.btn.delFlag': '刪除標記',
  'map.dataPushServer.status.running': '服務運行中',
};
