export default {
  'system.title': '系统参数设置',
  'system.event': '事件功能',
  'system.event.des': '是否支持事件功能',
  'system.plan': '按次计划',
  'system.plan.des': '能够周期性执行的计划，例如每天、每周、每月执行',
  'system.patroltime': '巡检用时',
  'system.patroltime.des': '是否在巡检报告中显示每圈计划巡检总时间',
  'system.remaintime': '停留时间',
  'system.remaintime.des': '是否在巡检报告中显示每个点的停留时间',
  'system.Sequence': '顺序巡检',
  'system.Sequence.des': '是否指定计划内的每个巡检点必须按照计划中设置的顺序进行巡检',
  'system.timeformate': '日期格式',
  'system.timeformate.des': '系统显示的日期格式',
  'system.database.period': '数据库备份周期',
  'system.database.period.des': '多久对数据库进行备份一次，单位：天',
  'system.database.time': '数据库备份时间',
  'system.database.time.des': '请尽量选择在数据上传低峰时间',
  'system.database.path': '数据库备份路径',
  'system.database.path.des': '请输入数据库备份路径，例如：D:\\backup，路径为空则代表不备份数据库',
  'system.save': '保存设置',
  'system.enable': '启用',
  'system.disable': '关闭',
  'system.notification.prompt': '提示',
  'system.notification.success': '保存成功！',
  'system.back.title': '数据库备份',
  'system.back.desc': '畚份系统数据或将系统数据恢复到某一时间点',
  'system.back.lastBackTime': '上次备份时间：',
  'system.back.managementBack': '管理备份',
  'system.deptBaseDataCalMode.title': '区域基础数据计算方式',
  'system.deptBaseDataCalMode.desc':
    '巡检设置下各基础数据管理界面，区域树形节点下基础类型数量的计算方式',
  'system.deptBaseDataCalMode.options.currentDept': '仅计算当前区域',
  'system.deptBaseDataCalMode.options.childrenDept': '计算当前区域及下级所有',
  'system.dst': '夏令时',
  'system.dst.des': '当前企业是否启用夏令时模式',
  'system.dst.preview': '夏令时周期：{beginTime}～{endTime}，跨度：{spanHour}小时',
  'system.dst.beginMonth': '开始月份',
  'system.dst.endMonth': '结束月份',
  'system.dst.spanHour': '跨度（小时）',
  'system.dst.weekVal': '星期',
  'system.dst.weekIndex': '第{indexVal}个',
  'system.dst.dataNullErrorMsg': '夏令时未配置，请修改',
  'system.dst.beginMonthGeEndError': '开始月份必须小于结束月份',
  'system.dst.weekIndex.tooltip':
    '如果月份内没有指定数量的星期数，那么程序将按照最后一周的指定星期来计算。',
  'system.lowAutoSendInterval.title': '低电量报警间隔时间（单位：分钟）',
  'system.lowAutoSendInterval.desc': '设置设备低电量报警时，推送数据并提醒用户的时间间隔',
  'system.realTimeMonitorModule.title': '实时监控模式',
  'system.realTimeMonitorModule.desc': '设置实时监控使用地图或示意图，也关联影响着电子围栏是否生效',
  'system.realTimeMonitorModule.options.map': '在线地图',
  'system.realTimeMonitorModule.options.imgFile': '示意图',
  'system.compantTimezoneUpt.syncMsg': '企业默认时区发生变更，是否同步未变动过的区域时区',
  'system.timezone.des': '当前企业的默认时区，主要应用于区域的时区配置，和设备校时等涉及时区的功能',
  'system.deptTimezone.title': '区域时区设置',
  'system.deptTimezone.des':
    '是否支持区域自定义调整时区，开启后也将允许设备自行管理所在时区，关闭后还原时区为企业时区。',
  'system.deptEmail': '区域邮件服务',
  'system.deptEmail.des': '开启后将会向为区域设置的邮件推送巡检相关的数据',
  'system.deptEmail.enableOmit': '推送计划漏检报警',
  'system.deptEmail.enableAlarm': '推送计划外报警',
  'system.deptEmail.enableReport': '推送当日巡检报告',
  'system.deptEmail.emailServerHost': '邮件服务器地址',
  'system.deptEmail.emailServerAccount': '邮件服务账号',
  'system.deptEmail.emailServerPwd': '邮件服务密码',
  'system.enclosureModule.title': '电子围栏功能',
  'system.enclosureModule.des':
    '是否启用电子围栏功能。若实时监控模式选择示意图，即便启用也会屏蔽电子围栏功能。',
};
