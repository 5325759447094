export default {
  'site.title': '巡检地点',
  'site.btn.batch': '批量读取',
  'site.btn.add': '增加地点',
  'site.btn.addgps': '增加GPS地点',
  'site.btn.addcoll': '增加采集地点',
  'site.btn.edit': '编辑地点',
  'site.btn.del': '删除地点',
  'site.btn.import': '导入地点',
  'site.btn.export': '导出地点',
  'site.btn.download': '下载数据',
  'site.btn.assign': '分配内容',
  'site.btn.move': '移动地点',
  'site.btn.rnd': '随机分配地点',
  'site.btn.rnd.del': '删除所有地点',
  'site.modal.position': '地理位置',
  'site.table.code': '地点卡号',
  'site.table.name': '地点名称',
  'site.table.type': '地点类型',
  'site.table.longitude': '经度',
  'site.table.latitude': '纬度',
  'site.table.range': '误差(米)',
  'site.table.voice': '配置语音',
  'site.table.site': '地理位置',
  'site.table.app.content': '内容标题',
  'site.table.app.des': '内容描述',
  'site.table.app.submission': '提交方式',
  'site.table.app.qr': '二维码点',
  'site.table.app.bluetooth': '蓝牙',
  'site.table.app.em': 'EM卡',
  'site.table.app.single': '单选',
  'site.table.app.multiple': '多选',
  'site.table.app.enter': '输入',
  'site.table.app.colluser': '采集人员',
  'site.table.app.colltime': '采集时间',
  'site.table.voice.clear': '清除设备语音',
  'site.table.voice.clear.success': '语音清除成功',
  'site.table.voice.clear.failed': '语音清除失败',
  'site.table.voice.clear.confirm': '确定要删除已经下载到设备中的语音吗？',
  'site.table.edit': '编辑',
  'site.input.site.placeholder': '请输入地点名称',
  'site.input.code.placeholder': '请输入地点卡号',
  'site.Transfer.Unselected': '待选语音',
  'site.Transfer.selected': '已选语音',
  'site.headerTitle.GPS': 'GPS地点列表',
  'site.headerTitle.All': '已配置语音及GPS地点列表',
  'site.notification.filtercode': '已经过滤掉地点卡号重复的记录',
  'site.notification.filtername': '已经过滤掉地点名称重复的记录',
  'site.notification.download.success': '数据下载成功',
  'site.notification.mostsite': '选择的普通地点数量最多不能超过1000个，请重新选择',
  'site.notification.mostgpssite': '选择的GPS地点数量最多不能超过500个，请重新选择',
  'site.notification.delposition': '确定要删除地图中的地点坐标吗？',
  'site.table.moveup': '上移地点',
  'site.table.movedown': '下移地点',
  'site.hasBindPlan.delconfirm': '待删除的地点已绑定了巡检计划，是否仍然删除？',
};
